.se-titleSection {
    width: 100%;
    height: 75vh;
    min-width: 1200px;
    text-align: center;
    display: inline-block;
}

.se-ts-bg {
    background: url(../../Images/ServiciosEspecialesPortada.jpg) no-repeat bottom right;
    background-size: cover;
    width: 100%;
    height: inherit;
    z-index: 300;
    position: absolute;
    min-width: 1200px;
    filter: brightness(0.8);
}
.se-infoContainer {
    height: fit-content;
    min-width: 1200px;
    background-color: whitesmoke;
}

