.SobreNosotrosSectionWrapper{
    width: 1200px;
    height: 875px;
    position: relative;
    display: inline-block;
    text-align: center;
    justify-content: center;
    margin-bottom: 25px;
}
.sn-background{
    background-image: url('../../../../Images/AboutUs.jpg');
    background-size: cover;
    width: inherit;
    height: 100%;
    position: absolute;
    z-index: 598;
}
.sn-blur{
    position: absolute;
    margin: 65px 75px;
    width: 1050px;
    height: 775px;
    background-color: rgba(0, 0, 0, .7);
    z-index: 599;
}
.sn-info{
    position: absolute;
    width: 1000px;
    margin-top: 40px;
    margin-left: 100px;
    margin-right: 100px;
    z-index: 600;
}

.sn-container{
    height: auto;
    width: auto;
    text-align: start;
    justify-content: space-between;
}

.sn-description{
    width: 800px;
}

.sn-textSection{
    width: 600px;
    display: inline-block;
    text-align: justify;
}

.sn-ts-title{
    color: whitesmoke;
    font-size: 50px;
    font-weight: bolder;
}
.sn-ts-desc{
    width: auto;
    color: whitesmoke;
    font-size: 18px;
    font-weight: 400;
    margin-right: 50px;
}

.sn-details{
    height: 350px;
    width: 70%;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sn-d-cards{
    width: 200px;
    height: 345px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 15px 15px 150px 150px;
    border-color: darkgoldenrod;
    border-width: 10px;
    border-style: double;
}
.sn-d-c-container{
    margin: auto;
    width: 150px;
}
.sn-d-c-expNumber{
    margin-top: 5px;
    color: whitesmoke;
    font-size: 80px;
    font-weight: bold;
    text-shadow: 0 0 10px darkgoldenrod, 0 0 50px darkgoldenrod;
}
.sn-d-c-expTitle{
    margin-top: -7px;
    color: whitesmoke;
    font-size: 20px;
    font-weight: bold;
}
.sn-d-c-expDesc{
    margin-top: 15px;
    color: whitesmoke;
}

.sn-d-c-estNumber{
    color: whitesmoke;
    margin-top: -25px;
    font-size: 80px;
    font-weight: bold;
    text-shadow: 0 0 10px darkgoldenrod, 0 0 50px darkgoldenrod;
}
.sn-d-c-estTitle1{
    margin-top: 10px;
    color: whitesmoke;
    font-size: 15px;
    font-weight: bold;
}
.sn-d-c-estTitle2{
    margin-top: -7px;
    color: whitesmoke;
    font-size: 20px;
    font-weight: bold;
}
.sn-d-c-estDesc{
    margin-top: 15px;
    color: whitesmoke;
}