.al-titleSection{
    width: 100%;
    height: 75vh;
    min-width: 1200px;
    text-align: center;
    display: inline-block;
}
.al-ts-bg{
    background: url(/src/Images/AsesoriaLegalPortada.jpg) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: inherit;
    z-index: 300;
    position: absolute;
    min-width: 1200px;
    filter: brightness(0.8);
}
.al-infoContainer{
    height: fit-content;
    width: inherit;
    background-color: whitesmoke;
    padding-bottom: 25px;
    margin-bottom: 25px;
    min-width: 1200px;
}
.al-info-title{
    margin-top: 25px;
    text-align: start;
    font-weight: 700;
    font-size: 50px;
}