.ss-sc-background{
    height: 400px;
    width: 240px;
    display: inline-block;
    text-align: center;
}

.ss-sc-bg-image{
    height: inherit;
    width: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 500;
    position: absolute;
}

.ss-sc-bg-blur{
    height: inherit;
    width: inherit;
    background-image: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.6));
    z-index: 501;
    position: absolute;
}

.ss-sc-textSection{
    height: inherit;
    width: inherit;
    z-index: 502;
    position: absolute;
    overflow: hidden;
}

.ss-sc-ts-textContainer{
    width: 100%;
    height: 150%;
    margin: 0 auto;
    padding-top: 140%;
    transition: all ease 0.7s;
}
.ss-sc-ts-textContainer:hover{
    padding-top: 30%;
    background-color: rgba(0, 0, 0, 0.6);
}

.ss-sc-ts-title{
    color: whitesmoke;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: bolder;
}
.ss-sc-ts-separador{
    height: 5px;
    width: 60%;
    background-color:whitesmoke;
    margin: 15px auto;
}
.ss-sc-ts-desc{
    margin-top: 25px;
    margin: auto;
    width: 80%;
    color: whitesmoke;
    text-align: justify;
    font-size: 15px;
    font-weight: 500;
}

.ss-sc-ts-btn{
    margin: 0 auto;
    margin-top: 50px;
    border-radius: 15px;
    border: 2px solid cadetblue;
    color:cadetblue;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 150px;
    box-shadow: 0 0 5px cadetblue, 0 0 25px cadetblue;
    cursor:pointer;
}
.ss-sc-ts-btn:hover{
    color: white;
    background-color: cadetblue;
}