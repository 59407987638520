.gsl_wrapper{
    margin: 25px auto;
    width: 100%;
    height: fit-content;
    justify-content: center;
    padding: 25px;
    background-color: whitesmoke;
    display: flex;
    min-width: 1200px;
}
.gsl_titles{
    width: 40%;
}
.gsl_Title{
    margin: 10px 0px 5px 0;
    width: 1000px;
    color: rgb(34, 34, 34);
    font-size: 30px;
    font-weight: 700;
    text-align: start;
}
.gsl_subtitle{
    margin: 5px 0px 25px 0px;
    text-align: start;
    font-weight: 400;
    font-size: 20px;
}

.gsl_buttonwrapper{
    width: 40%;
}

/*----------Button Styles---------------*/
.gsl_buttonwrapper{
    margin: 0;
        padding: 0;
        box-sizing: border-box;
}
.gsl_button {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif; 
  }
  .gsl_button a {
    text-decoration: none;
    margin: 15px;
    border: none;
    padding: 10px 20px;
    font-size: 40px;
    position: relative;
    background: transparent;
    color: cadetblue;
    text-transform: uppercase;
    border: 3px solid cadetblue;
    cursor: pointer;
    transition: all 0.7s;
    overflow: hidden;
    border-radius: 100px;
  }

  .gsl_button a:hover {
    color: whitesmoke;
    background-color: cadetblue;
  }
  
