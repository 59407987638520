.ServicesSectionWrapper{
    background-color: rgba(200, 150, 100, .0);
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    background-color: whitesmoke;
    margin-bottom: 25px;
    height: 900px;
}

.ss-container{
    margin: 25px auto;
    width: 80%;
    height: 500px;
    display: flex;
    justify-content: space-between;
}
.ss-description{
    text-align: start;
    margin-bottom: 50px;
}

