.TestimoniosSectionWrapper{
    width: 1200px;
    height: 800px;
    position: relative;
    display: inline-block;
    text-align: center;
    justify-content: center;
    margin-bottom: 25px;    
    padding-top: 10px;
    background-color: whitesmoke;
}

.ts-titles{
    padding-left: 25px;
    padding-right: 25px;
}
.ts-heroCards{
    margin: 25px auto;
    width: 90%;
    height: 500px;
}
.ts-hc-container{
    display: flex;
    height: inherit;
    gap: 25px;
}