.sectionTitleWrapper{
    margin-top: 50px;
    width: inherit;
    justify-content: start;
    text-align: start;
}

.st-subtitle{
    font-size: 25px;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}
.st-seperator{
    margin-top: 10px;
    height: 5px;
    width: 100%;
    background-color: rgb(95, 158, 160);
}

.st-title{
    font-size: 50px;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
}