.sp-infoCardsContainer{
    width: inherit;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.sp-info-title {
    margin: 10px 0px 5px 0;
    text-align: start;
    font-weight: 700;
    font-size: 60px;
}

.sp-info-subtitle {
    margin: 5px 0px 25px 0px;
    text-align: start;
    font-weight: 400;
    font-size: 20px;
}