.tc-card{
    flex: 1;
    border-radius: 5px;
    border: 2px solid cadetblue;
    height: 400px;
    width: 200px;
    background-color: whitesmoke;
    transition: all ease 0.3s;
    padding: 5px;
}

.tc-profilePhoto{
    border: 2px solid darkcyan;
    background: url('../../../../Images/ProfileImage01.jpg') no-repeat center center;
    background-size: cover;
    margin: 15px auto;
    border-radius: 90px;
    height: 130px;
    width: 130px;
}

.tc-quote{
    visibility: hidden;
    height: 0px;
}
.tc-quote-text{
    color: rgba(34, 34, 34, .0);
    font-size: 22px;
    font-weight: 800;
}

.tc-separator{
    background-color: cadetblue;
    height: 5px;
    width: 80%;
    margin: auto;
    transition: all ease 0.1s;
}

.tc-profileName{
    height: 40px;
    color: rgb(34, 34, 34);
    line-height: 1.1;
    font-size: large;
    font-weight: 700;
    margin: 15px auto 0 auto;
    transition: all ease 0.2s;
    
}

.tc-profileRole{
    height: 20px;
    color: rgb(34, 34, 34);
    font-size: medium;
    font-weight: 500;
    margin: auto;
    transition: all ease 0.2s;
}
.tc-profileCompanyLogo{
    margin: 25px auto;
    background: url(../../../../Images/LogoCompany01.jpg) center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 150px;
    transition: all ease 0.2s;
}

/*Hover Section*/

.tc-card:hover{
    transition: all ease 0.3s;
    display: flex;
    flex-direction: row;
    flex:5;
    align-items: end;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 2.5s;
    
}

.tc-card:hover .tc-profileWrapper{
    height: inherit;
    width: 450px;
    position: absolute;
    z-index: 700;
    overflow: hidden;
}

.tc-card:hover .tc-profilePhoto{
    transition: all ease 0.3s;
    position: absolute;
    border: 5px solid gray;    
    border-radius: 250px;
    background: url('../../../../Images/ProfileImage01.jpg') no-repeat center center;
    background-size: cover;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 450px;
    height: 450px;
    transform: translate(-100px,100px);
    filter: opacity(0.4);
}

.tc-card:hover .tc-textWrapper{
    height: 100%;
    width: 100%;
    z-index: 701;
    display: block;
    position: relative;
    text-align: right;
}

.tc-card:hover .tc-quote{
    visibility: visible;
    font-size: 22px;
    font-weight: 800;
    margin-top: 25px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: 30px;
    width: 75%;
    height: 180px;
    right: 0;
    background-color: rgba(245, 245, 245, .6);
    padding: 5px;
    border-radius: 25px;    
}

.tc-card:hover .tc-quote-text{
    transition: all ease 0.3s;
    transition-delay: 0.3s;
    color: rgb(34, 34, 34);
}

.tc-card:hover .tc-separator{
    height: 10px;
    width: 100%;
    margin: auto;
    transition: all ease 0.3s;
}

.tc-card:hover .tc-profileName{
    margin-left: auto;
    margin-right: 30px;
    font-size: 21px;
    transition: all ease 0.3s;
}

.tc-card:hover .tc-profileRole{
    margin-left: auto;
    margin-right: 30px;
    font-size: 17px;
    transition: all ease 0.3s;
}

.tc-card:hover .tc-profileCompanyLogo{
    margin-top: 10px;
    margin-left: auto;
    margin-right: 30px;
    width: 200px;
    height: 80px;
}