/*InfoCards*/
.sec-Container{
    width: 560px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
}

.sec-infoCard {
    max-width: 600px;
    height: 270px;
    flex: 1 0 560px;
    box-shadow: 0 0 5px rgb(34, 34, 34), 0 0 15px gray, 0 0 25px black;
    text-align: start;
    transform: scale(0.9);
    transition: all ease 1s;
    display: block;
    color: whitesmoke;
}

.sec-infoCard:hover {
    transform: scale(1);
    transition: all ease 1s;
}

.sec-infoCardTitle {
    padding: 10px;
    margin: 15px;
    font-size: 23px;
    font-weight: 500;
    border-bottom: 4px solid rgba(245, 245, 245, 0.2);
}

.sec-infoCardDetails {
    width: 100%;
    height: inherit;
    position: absolute;
    z-index: 102;
}
.sec-infoCardGradient{
    background-image: linear-gradient(to right, black, rgba(34,34,34,0.2));
    width: 100%;
    height: inherit;
    position: absolute;
    z-index: 101;
}
.sec-infoCardBackground{
    background-size: cover;
    width: 100%;
    height: inherit;
    position: absolute;
    z-index: 100;
}

.sec-infoCardSubtitle {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 50px;
}
