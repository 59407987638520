.se-galeriaWrapper {
    width: inherit;
    height: fit-content;
    justify-content: center;
    padding: 25px;
    background-color: whitesmoke;
}

.se-galeriaTitle {
    margin: 10px 0px 5px 0;
    width: 100%;
    height: 100px;
    color: rgb(34, 34, 34);
    font-size: 50px;
    font-weight: 700;
    text-align: start;
}

.se-galeriaContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
}

.se-videoCard {
    margin: auto;
    width: 1000px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 5px rgb(220, 220, 220), 0 0 15px gray, 0 0 25px black;
}

.se-videoPlayer {
    width: 50%;
}

.se-videoInfo {
    width: 50%;
    height: 300px;
}

.se-vi-title {
    text-align: start;
    margin: 15px 20px;
    border-bottom: 3px solid black;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
}

.se-vi-description {
    text-align: start;
    margin: 15px 20px;
}
.se-vi-imageContainer{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-around;
}
.se-vi-image-roboMass_01{
    background: url(../../../Images/Informes/RoboMass_01.jpg) center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 20px;
    height: 100px;
    width: 200px;
}
.se-vi-image-roboMass_02{
    background: url(../../../Images/Informes/RoboMass_02.jpg) center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 20px;
    height: 100px;
    width: 200px;
}
.se-vi-image-investig_01{
    background: url(../../../Images/Informes/Capturado01.png) center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 20px;
    height: 100px;
    width: 200px;
}
.se-vi-image-investig_02{
    background: url(../../../Images/Informes/Capturado01_Vehiculo.png) center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 20px;
    height: 100px;
    width: 200px;
}