.ge-titleSection{
    width: 100%;
    height: 75vh;
    min-width: 1200px;
    text-align: center;
    display: inline-block;
}
.ge-ts-bg{
    background: url(/src/Images/MediaRecording.webp) no-repeat center;
    background-size: cover;
    width: 100%;
    height: inherit;
    z-index: 300;
    position: absolute;
    min-width: 1200px;
    filter: brightness(0.8);
}
.ge-infoContainer{
    height: fit-content;
    width: inherit;
    background-color: whitesmoke;
    padding-bottom: 25px;
    min-width: 1200px;
}

