.NavigationMenuWrapper{
    position: fixed;
    margin-top: 0px;
    z-index: 9999;
    width: 100%;
    height: 75px;   
    transition: all ease-in .3s;
    overflow: hidden;
}
.HookedNavigationMenuWrapper{
    overflow: hidden;
    position: fixed;
    margin-top: 0px;
    z-index: 9999;
    width: 100%;
    height: 50px;    
    background-color: rgba(250,250,250,.95);
    transition: all ease-out .3s;
}

.LogoContainer{
    width: 300px;
    margin-top: 5px;
    height: 100px;
    transform: translateY(-15px);
    z-index: 9999;
    background-image: url('../../Images/logo_RP_consultores_min_horizontal.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all ease-out .3s;
}

.HookedLogoContainer{
    width: 300px;
    margin-top: 5px;
    margin-top: 0px;
    height: 75px;
    transform: translateY(-15px);
    padding: 0;
    z-index: 9999;
    background-image: url('../../Images/logo_RP_consultores_min_horizontal.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all ease-out .3s;
}

.nm-maxwidth{
    margin: auto;
    display: flex;
    flex-direction: row;
    max-width: 1200px;
}


.MenuOptContainer{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 800px;
    transition: all ease-out .3s;
}

.HookedMenuOptContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 800px;
    transition: all ease-out .3s;
}

.MenuElement{
    text-decoration: none;
    padding: 15px;
    color: whitesmoke;
    font-weight: bolder;
    font-size: 17px;
    width: 150px;
    transition: all ease .3s;
}

.MenuElement:hover{
    background-image: linear-gradient(to top, rgba(33,123,176,.8),rgba(0,0,0,0));
    border-radius: 15px;
    cursor: pointer;
    transform: translatey(10px);

}

.HookedMenuElement{
    text-decoration: none;
    padding: 15px;
    color: rgba(33,123,176,1);
    font-weight: bolder;
    font-size: 15px;
    width: 150px;
    transition: all ease .3s;
}
.HookedMenuElement:hover{
    border-radius: 18px;
    cursor: pointer;
    color: rgba(33,123,176,1);
    font-weight: lighter;
}

.CurrentSectionElement{
    background-color: rgba(33,123,176,1);
    border-radius: 15px;
    cursor: pointer;
    transform: translatey(10px);
    color: white;
}