.LandpageTitleWrapper{
    width: 100%;
    height: 100vh;
    min-width: 1200px;
    min-height: 500px;
    text-align: center;
    display: inline-block;
}

.lt-titleBg{
    background: url(/public/titleImage.webp) no-repeat center center fixed;
    background-size: cover;
    width: 100%;
    height: inherit;
    z-index: 300;
    position: absolute;
    min-width: 1200px;
    min-height: 500px;
    filter: brightness(0.5);
}

.lt-titleTextSection{
    width: 1200px;
    height: 60%;
    min-height: 400px;
    z-index: 500;
    position: relative;
    margin: calc(7.5% + 50px) auto;
}

.lt-ts-textPanel{
    text-align: start;
    width: 45%;
}
.lt-ts-tp{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: whitesmoke;
}

.lt-ts-tp.SupraTitle{
    margin-top: 30px;
    font-size: 25px;
    font-weight: 600;
}
.lt-ts-tp.Title{
    margin-top: 30px;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: -1px;
}
.lt-ts-tp.Details{
    margin-top: 30px;
    font-size: 20px;
}
.lt-ts-tp.Details a{
    color: rgb(123, 202, 248);
    font-weight: 700;
    font-size: 22px;
}

.lt-ts-tp.ButtonsSection{
    text-align: center;
    width: 100%;
    margin-top: 7vh;
    height: 60px;
    display: flex;
}

.lt-ts-tp-btn{
    padding: 10px 10px;
    font-weight: bolder;
    font-size: 20px;
    border: 5px solid rgba(33, 123, 176, .8);
    border-radius: 15px;
    margin-right: 15px;
    text-decoration: none;
}

.lt-ts-tp-btn.BtnNuestrosServicios{
    background-color: rgba(33, 123, 176, .8);
    color: whitesmoke;
    
}
.lt-ts-tp-btn.BtnNuestrosServicios:hover{
    transition: all ease .3s;
    color: rgba(33, 123, 176, .8);
    background-color: whitesmoke;
    border-color: whitesmoke;
    cursor: pointer;
}

.lt-ts-tp-btn.BtnContactanos{
    color:  whitesmoke;
}

.lt-ts-tp-btn.BtnContactanos:hover{
    transition: all ease .3s;
    color: rgba(33, 123, 176, .8);
    background-color: whitesmoke;
    border-color: whitesmoke;
    cursor: pointer;
}

