.sp-titleSection{
    width: 100%;
    height: 75vh;
    min-width: 1200px;
    text-align: center;
    display: inline-block;
}
.sp-ts-bg{
    background: url(/src/Images/SeguridadPatrimonialPortada.jpg) no-repeat center;
    background-size: cover;
    width: 100%;
    height: inherit;
    z-index: 300;
    position: absolute;
    min-width: 1200px;
    filter: brightness(0.8);
}
.sp-infoContainer{
    height: fit-content;
    width: inherit;
    background-color: whitesmoke;
    padding-bottom: 50px;
    min-width: 1200px;
}
