.ContactanosSectionWrapper{
    width: 1200px;
    height: auto;
    position: relative;
    display: inline-block;
    text-align: center;
    justify-content: center;
    margin-bottom: 25px;    
    background-color: rgb(34, 34, 34);
}
.c-titleWrapper{
    padding-left: 25px;
    padding-right: 25px;
}

.c-contentWrapper{
    height: 650px;
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.c-Image{
    background: url(../../../../Images/SecGuardHeadset.jpg) center center no-repeat;
    background-size: cover;
    filter: brightness(.4) ;
    flex: 2;
}
.c-textSection{
    flex: 3;
    text-align: start;
    align-items: start;
}
.ct-title{
    margin-top: 75px;
    font-size: 45px;
    font-weight: 700;
    margin-left: 50px;
    color: whitesmoke;
}

.ct-infoSection{
    padding: 25px;
    margin-top: 50px;
    margin-left: 100px;
    color:whitesmoke;
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(34, 34, 34), 0 0 15px gray, 0 0 25px black;
    width: 350px;
    height: 100px;
    
}
.ct-is-title{
    font-size: 25px;
    font-weight: 500;
}

.ct-is-info{
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 300;
}
.ct-is-info div{
    margin-right: 5px;
}